.leaderBoard {
    padding: 4rem;
    background: #263439;
}

.winnerPic  {
    max-width: 100%;
    height: auto;
    border-radius: 30px;
    background-color: #263439;
}

.winnerPicContainer {
    background-color: #263439;
}