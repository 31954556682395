* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

html, body {
    height: 100%;
}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}

.content {
    margin-top: 80px;
    flex: 1 1 auto;
    z-index: 1;
}

.footer {
    flex: 0 1 40px;
    z-index: 2;
}

#root {
    height: 100%;
}

