.clubInfo {
    padding: 4rem;
    background: #263439;
    height: 100%;
}

.container {
    margin: auto;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    color: white;
}

.container a {
    color: #FC6100;
}

.container iframe {
    margin-top: 2rem;
}
